// Global variables
$font-family-base: -apple-system,BlinkMacSystemFont,"Nunito Sans","Segoe UI","Helvetica Neue",Arial,sans-serif;
$font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
$font-size-base: 18px;
$text-weight-base: 400;
$line-height-base: 1.5;
$body-color: #ffffff;
$background-color: #030303;
$link-hover: #3989ef;
$z-index: 1000;

// Font sizes
$font-size-h1: 30px;
$font-size-h2: 24px;
$font-size-h3: 22px;
$font-size-h4: 18px;
$font-size-h5: 16px;
$font-size-h6: 15px;
$font-size-md: 15px;
$font-size-sm: 13px;
$font-size-xsm: 12px;
$font-size-xxsm: 11px;

// Font weight
$text-weight-light: 300;
$text-weight-regular: 400;
$text-weight-md: 500;
$text-weight-semibold: 700;
$text-weight-bold: 900;

// Colors
$color-white: #fff;
$color-black: #000;
$color-darker: #0d1e18;
$color-heavy-dark: #101112;
$color-heavy-darker: #161313;
$color-charcoal: #4b4e54;
$color-primary: #7ccbe1;
$color-pastel-blue: #519cbd;
$color-blue: #0d6efd;
$color-secondary: #6c757d;
$color-success: #122a22;
$color-warning: #f1cf6f;
$color-danger: #dc3545;
$color-info: #3ECCB0;
$color-light: #f8f9fa;
$color-dark: #11251e;
$color-lighter-gray: #fdfdfd;
$color-fade-gray: #eff0ef;
$color-light-red: #ffd1d2;
$color-cool-red: #ff9092;
$color-pastel-red: #ff9092;
$color-bright-red: #f16d6f;
$color-slate-gray: #666;
$color-cool-gray: #d1d1d1;
$color-gray: #9198a6;
$color-light-gray: #d2ddeb;
$color-dark-gray: #262626;
$color-slate-gray: #67798e;
$color-inactive: #f8f8f8;
$color-charcoal: #222122;
$color-light-green: #b9e4d8;
$color-glow-green: #f0fffb;
$color-pastel-green: #b7c7c4;
$color-dark-green: #3d5b57;
$color-dark-blue: #00203e;

$section-background-color: #b9e3da; //#04ad8c; //#e9e9e9;
$banner-background-color: $color-success;
$color-link: #fff;
$color-link-hover: #8dc9be;

// Disabled
$input-disabled-color: $color-light-gray;
$input-disabled-background-color: $color-inactive;

// Buttons
$primary-button-background: #3ECCB0;
$primary-button-text: #000;
$primary-border-color: #3ECCB0;
$secondary-button-background: transparent;
$secondary-button-text: #3ECCB0;
$secondary-border-color: #3ECCB0;
$button-disabled-text: $color-white;

// Modal
$modal-overlay: rgba(19, 43, 79, .58);
$modal-overlay-dark: rgba(19, 43, 79, .84);

// General input variables
$input-padding: 0.375rem 0.625rem;
$search-input-border-color: $color-light-gray;
