.countdown-timer {
  @include display-flex(center);

  &__item {
    @include display-flex(center);
    flex-direction: column;

    &:not(:last-child) {
      margin-right: 1.5rem;
    }
  }

  &__time {
    @include display-flex(center, center);
    position: relative;
    width: 4.5rem;
    height: 4.5rem;
    border: 1px solid $color-heavy-darker;
    background-color: rgb(253 222 222 / 18%);
    border-radius: .25rem;
    overflow: hidden;

    > h1 {
      color: $color-white;
      margin-bottom: 0;
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgb(201 59 55 / 13%);
    }
  }

  &__label {
    font-size: $font-size-sm;
    color: $color-slate-gray;
    font-weight: $text-weight-md;
    margin: .5rem 0;
  }
}
