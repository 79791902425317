.nav {
  @include display-flex(center);
  position: relative;

  &-button {
    background-color: transparent;
    color: white;
    border: 0;
  }

  &-menu {
    display: none;
    position: absolute;
    color: $color-white;
    top: 2rem;
    background-color: $background-color;

    &.visible {
      display: block;
    }
  }

  &-list {
    padding: 1rem 1rem 0;
    min-width: 12rem;
    list-style: none;

    li {
      height: 2rem;
    }
  }
}

.nav-item {
  & > a {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: $color-link;
    padding: .5rem 1.25rem !important;
    text-decoration: none;
    cursor: pointer;

    @media screen and (max-width: 586px) {
      padding-left: .75rem !important;
      padding-right: .75rem !important;
    }
  }

  &--active, &:hover {
    & > a:not(.btn) {
      color: $color-link-hover !important;
    }
  }
}
