// Sidebar overlay
.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $modal-overlay;
  opacity: 1;
  z-index: ($z-index + 2);
}

.modal-overlay--dark {
  background-color: $modal-overlay-dark;
}

.ember-modal {

  // Modal overlay
  &-overlay {
    z-index: ($z-index + 1);

    &.translucent {
      @extend .modal-overlay;
      // Bring overlay over slider modal
      z-index: ($z-index + 5) !important;
    }

    &--dark {
      &.translucent {
        @extend .modal-overlay--dark;
      }
    }
  }

  &-dialog {
    max-width: 36rem;
    width: calc(100% - 2rem); // To add margin
    padding: 0;
    @extend .box-shadow-dropdown;

    &__header,
    &__body,
    &__footer {
      padding: 1.25rem;
    }

    &__header {
      @include display-flex(center, space-between);
      padding-bottom: 0;

      > h4 {
        font-weight: $text-weight-md;
        margin-bottom: 0;
        margin-right: 1rem;
      }

      // close button
      > .btn--action {
        margin-left: auto;
      }
    }

    &__close {
      padding: .5rem 1rem !important;
      margin: -.5rem -1.25rem;

      > svg {
        @extend .icon-close;
      }
      &:hover > svg {
        @extend .icon-close-hover;
      }
    }

    &__body {
      min-height: 8rem;
      max-height: calc((1vh * 100) - 120px); // Do not change
      overflow: auto;
    }

    &__footer {
      padding-top: .75rem;
      //border-top: 1px solid $color-cool-gray;
      border-bottom-right-radius: .5rem;
      border-bottom-left-radius: .5rem;

      .btn-group {
        margin-top: 0; // To handle .btn-group--lg issue for forms
        padding-top: 0;
        padding-bottom: 0;
        justify-content: flex-end;
      }
    }
  }
}

// This is also used for ember popup modal
@keyframes drop-fade-modal {
  0%   {
    opacity: 0;
    transform: translate3d(-50%, -4rem, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(-50%, 0, 0);
  }
}
