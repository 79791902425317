.icon {
  // Model close icon
  &-close {
    @include transform-rotate(0deg);
    @extend .transition-ease-primary;
  }
  &-close-hover {
    @include transform-rotate(90deg);
  }
  // Hover effect
  &-hover {
    &:hover {
      path[fill] {
        fill: $color-primary;
      }
    }
  }

  // Default icon color
  &-default {
    path[fill] {
      fill: $color-gray;
    }
    path[stroke] {
      stroke: $color-gray;
    }
  }

  // Icon sizes
  &-xxsm {
    width: .75rem; // Do not change
    height: .75rem;
  }
  &-xsm {
    width: .875rem; // Do not change
    height: .875rem;
  }
  &-sm {
    width: 1rem;
    height: 1rem;
  }
  &-smd {
    width: 1.125rem; // Do not change
    height: 1.125rem;
  }
  &-md {
    width: 1.5rem; // do not change
    height: 1.5rem;
  }
  &-lg {
    width: 1.75rem;
    height: 1.75rem;
  }
  &-xlg {
    width: 2.5rem;
    height: 2.5rem;
  }
}
