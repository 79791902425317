@import 'helpers/image';
@import 'helpers/transition';
@import 'helpers/background';
@import 'helpers/separator';
@import 'helpers/circle';
@import 'helpers/text';
@import 'helpers/sizing';
@import 'helpers/border';
@import 'helpers/block_wrapper';
@import 'helpers/animation';
@import 'helpers/checkbox';

// Used for dropdown modals, ember-power select etc..
.box-shadow-dropdown {
  @include box-shadow(0px, 3px, 10px, rgba(88, 122, 188, 0.4));
}
