.img {
  //Sizes
  &-xsm {
    width: 1rem;
    height: 1rem;
  }
  &-sm {
    width: 2rem;
    height: 2rem;
  }
  &-md {
    width: 4rem;
    height: 4rem;
  }
  &-lg {
    width: 6rem;
    height: 6rem;
  }
  &-xlg {
    width: 8rem;
    height: 8rem;
  }
  &-xxlg {
    width: 12rem;
    height: 12rem;
  }
}
