.bg {
  &-black {
    background-color: $color-charcoal !important;
  }
  &-danger {
    background-color: $color-danger !important;
  }
  &-warning {
    background-color: $color-danger !important;
  }
  &-dark {
    background: $color-dark !important;
  }
  &-darker {
    background: $color-darker !important;
  }
}
