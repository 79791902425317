.hs-25 {
  height: 1.563rem !important;
}
.hs-50 {
  height: 3.125rem !important;
}
.hs-75 {
  height: 4.688rem !important;
}
.hs-100 {
  height: 6.25rem !important;
}
.hs-125 {
  height: 7.813rem !important;
}
.hs-150 {
  height: 9.375rem !important;
}
.hs-175 {
  height: 10.938rem !important;
}
.hs-200 {
  height: 12.5rem !important;
}
// Min height
.hmn-25 {
  min-height: 1.563rem !important;
}
.hmn-50 {
  min-height: 3.125rem !important;
}
.hmn-75 {
  min-height: 4.688rem !important;
}
.hmn-100 {
  min-height: 6.25rem !important;
}
.hmn-125 {
  min-height: 7.813rem !important;
}
.hmn-150 {
  min-height: 9.375rem !important;
}
.hmn-175 {
  min-height: 10.938rem !important;
}
.hmn-200 {
  min-height: 12.5rem !important;
}
