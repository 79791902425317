.link-text {
  color: $color-charcoal;
  margin-bottom: .5rem;
  text-decoration: none;
  @extend .transition-ease-primary;

  &:hover {
    color: $color-success;
    border-bottom: 1px solid $color-success;
  }
}

// Text sizes
.text-xxsm {
  font-size: $font-size-xsm !important;
}
.text-xsm {
  font-size: $font-size-sm !important;
}
.text-sm {
  font-size: $font-size-md !important;
}
.text-md {
  font-size: $font-size-md !important;
}
.text-lg {
  font-size: $font-size-h5 !important;
}
.text-xlg {
  font-size: $font-size-h4 !important;
}
.text-2xlg {
  font-size: $font-size-h3 !important;
}

// Colors
.text-primary {
  color: $color-primary !important;
}
.text-secondary {
  color: $color-secondary !important;
}
.text-success {
  color: $color-success !important;
}
.text-danger {
  color: $color-danger !important;
}
.text-warning {
  color: $color-warning !important;
}
.text-light {
  color: $color-light !important;
}
.text-info {
  color: $color-info !important;
}
.text-dark {
  color: $color-dark !important;
}
.text-gray {
  color: $color-gray !important;
}
.text-pastel-green {
  color: $color-pastel-green !important;
}

// Direction
.text-right {
  text-align: right;
}
