.footer {

  &-wrapper {
    border-top: 1px solid rgb(255 255 255 / 8%);
  }

  &-links-wrapper {
    border-top: 1px solid $color-light;
    padding: 1rem 0;
  }

  a {
    color: #666666;
    font-size: 1.125rem;
    line-height: 1.5;

    &:hover, &:active, &:focus {
      color: $color-primary;
    }
  }
}
