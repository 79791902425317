.social-icons {
  @media screen and (min-width: 768px) {
    color: $color-white;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #080808 0%, #030303 100%);
    backdrop-filter: blur(20px);
    border-radius: 36px;
    padding: .5rem;
    position: fixed;
    left: 0;
    top: 20%;
    width: 72px;
    align-items: center;
    height: 190px;
    justify-content: space-between;
  }
}
