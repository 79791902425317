.card {
  background: linear-gradient(180deg, #0e0e0e 0%, #060606 100%);
  border-radius: .5rem;
  border: 1px solid;
  border-image-source: linear-gradient(180deg, #1F1F1F 0%, #0D0D0D 100%);
  min-height: 18.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &-header {
    position: relative;
    padding: 1rem;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: #1a1a1a;
    }
  }

  &-body {
    padding: 1rem;

    @media (min-width: 768px) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  &-title {
    text-transform: uppercase;
    color: #fff;
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 1.1;
    text-align: center;
  }

  &-description {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.3;
    text-align: center;
    color: #666;
  }
}
