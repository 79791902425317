.alert {

  // Types
  &--line {
    background-color: transparent;
    border-left: .25rem solid;
    border-top: none;
    border-right: none;
    border-bottom: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;

    &.alert-primary {
      color: darken($color-primary, 23);
      border-left-color: $color-primary;
    }
    &.alert-secondary {
      color: darken($color-secondary, 23);
      border-left-color: $color-secondary;
    }
    &.alert-success {
      color: darken($color-success, 23);
      border-left-color: $color-success;
    }
    &.alert-danger {
      color: darken($color-danger, 23);
      border-left-color: $color-danger;
    }
    &.alert-warning {
      color: darken($color-warning, 23);
      border-left-color: $color-warning;
    }
    &.alert-light {
      color: darken($color-light, 23);
      border-left-color: $color-light;
    }
    &.alert-info {
      color: darken($color-info, 17);
      border-left-color: $color-info;
    }
    &.alert-dark {
      color: darken($color-dark, 23);
      border-left-color: $color-dark;
    }
  }
}
