.page-banner {
  background-color: transparent;
  height: 100%;
  padding-top: 0;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;

  @media screen and (min-width: 768px) {
    padding-bottom: 9rem;
  }

  > .container {
    height: 100%;

    img {
      width: auto;
      max-width: 100%;
      height: auto;
    }
  }

  &-title {
    color: $color-white;
    font-size: 4.25rem;
    font-weight: 900;
    line-height: 1.3;
    margin-bottom: 1.25rem;

    @media screen and (max-width: 992px) {
      font-size: 3rem;
    }
  }

  &-subtitle {
    color: $color-white;
    font-size: 3.5rem;
    font-weight: 400;
    line-height: 1.2;
  }

  &-text {
    color: $color-slate-gray;
    margin-bottom: 1.25rem;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.1;
  }

  .action-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    max-width: 65%;

    .actions {
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 768px) {
        flex-direction: row;
      }
    }
  }
}
