.circle {

  &-sm, &-md, &-lg, &-xlg {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0 0 0 / 16%);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }

  &-sm {
    font-size: 14px;
    width: 1rem;
    height: 1rem;
  }

  &-md {
    font-size: 18px;
    width: 2rem;
    height: 2rem;
  }

  &-lg {
    font-size: 22px;
    width: 3rem;
    height: 3rem;
  }

  &-xlg {
    font-size: 26px;
    width: 4rem;
    height: 4rem;
  }
}
