.container--focus {
  background: $color-dark;
  -webkit-border-radius: 24rem;
  -moz-border-radius: 24rem;
  border-radius: 24rem;
  -webkit-box-shadow: 0 0 0 .125rem $color-dark-green;
  -moz-box-shadow: 0 0 0 .125rem $color-dark-green;
  box-shadow: 0 0 0 .125rem $color-dark-green;

  @media screen and (max-width: 992px) {
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    border-radius: 1rem;
  }
}

.container--overlap {
  position: absolute;
  left: 0;
  margin-top: -6rem;
}
