.header {
  @include display-flex(unset, center);
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;

  &__logo {
    display: flex;
    align-items: center;
    text-decoration: none;

    & > img {
      display: block;
      height: 3.5rem;
      max-width: 100%;
      width: auto;
      border: none;
      vertical-align: middle;
    }

    &-title {
      color: $color-primary;
      font-size: $font-size-h5;
      font-weight: $text-weight-bold;
      margin-left: 1rem;
      text-transform: uppercase;

      > span {
        color: $color-warning;
      }
    }
  }
}
