.timeline-container {
  display: block;
  background: linear-gradient(180deg, #080808 0%, #030303 100%);
  border: 1px solid #141414;
  border-radius: .5rem;
  padding: 2rem;
}

.timeline {
  display: flex;

  &-progress {
    flex: 1;
  }

  &-progress:not(:first-child) {
    margin-left: .5rem;
  }

  &-progress-fill {
    height: 12px;
    border-radius: 33px;
    background: linear-gradient(180deg, #3ECCB0 0%, #1E6254 100%);
  }

  &-progress-text {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: #121212;
    border-radius: .5rem;
    border: 1px solid;
    border-image-source: linear-gradient(180deg, #1F1F1F 0%, #0D0D0D 100%);

    @media (min-width: 768px) {
      max-width: 50%;
      margin: 1rem auto 0;
    }
  }
}
