.platforms {
  align-items: center;
  padding-top: 2rem;

  &__item {
    opacity: 0.4;
    @extend .transition-ease-primary;

    img {
      max-height: 3rem;
    }

    &:hover {
      opacity: 1;
    }
  }
}
