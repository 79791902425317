.mint-counter {
  display: flex;
  align-items: center;

  .mint-button {
    width: 3rem;
    height: 3rem;
    transition: all 0.5s ease;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    background-color: transparent;
    border: 1px solid #3ECCB0;
    color: #fff;
    border-radius: 0.5rem;
  }

  .mint-amount {
    font-size: 3rem;
    font-weight: 700;
    color: #fff;
    margin: 0 2rem;
  }
}

