$badge-radius: 3.125rem;

.badge-switch {
  font-size: 1.5rem;
  font-weight: $text-weight-md;
  background-color: $color-fade-gray;

  &,
  &__beacon {
    display: inline-flex;
    align-items: center;
    -webkit-border-radius: $badge-radius;
    -moz-border-radius: $badge-radius;
    border-radius: $badge-radius;
  }

  &__beacon {
    color: $color-white;
    background-color: $color-dark-blue;
    padding: .75rem 1.5rem;
    @extend .transition-ease-primary;

    > h1 {
      font-size: 2.5rem;
      font-weight: $text-weight-md;
      margin-bottom: 0;
      margin-left: 1.25rem;
    }

    &-icon {
      padding-right: 1.25rem;
      border-right: 3px solid rgb(255 255 255 / 10%);
    }
  }

  &__text {
    padding: 0 2rem;

    @media screen and (max-width: 568px) {
      max-width: 11.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
