.checkbox {
  @include display-flex(center, center);
  flex: 0 0 auto;
  width: 1.25rem;
  height: 1.25rem;
  background-color: $color-light;
  padding: .25rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;

  > svg {
    width: .875rem;
    height: .875rem;
  }
}
