// Rotation
.animate-spin {
  -webkit-animation:spin 200s linear infinite;
  -moz-animation:spin 200s linear infinite;
  animation:spin 200s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
