$progress-width: var(--progress-width);

.progress {
  padding: .375rem;
  border-radius: 2rem;
  background: rgba(0, 0, 0, 0.25);
  box-sizing: content-box;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar {
  height: 1.25rem;
  border-radius: 2rem;
  box-sizing: content-box;
  background-image:
    linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.progress-animate .progress-bar {
  width: $progress-width;
  background-color: #17a176;
  animation: progressAnimate 6s;
}

@keyframes progressAnimate {
  0%   { width: 5%; background-color: #cce9e0;}
  100% { width: $progress-width; background-color: #17a176; }
}
