$item-background-color: rgb(255 255 255 / 11%);

.social {
  &-wrapper {
    width: auto;
    background: rgb(255 255 255 / 7%);
    box-shadow: 0 0.25rem 0.25rem rgb(255 115 115 / 19%) !important;
  }

  &-items {
    display: flex;
    align-items: center;

    &__item {
      background: $item-background-color;
      margin: 0 0.5rem;
      border-radius: 0.5rem;

      > a {
        display: block;
        padding: 0.75rem;
      }

      &:hover {
        background-color: darken($item-background-color, 25);
      }
    }
  }
}
